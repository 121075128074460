<script setup>
import { ref, onMounted } from 'vue'
// import { PROD_URL } from "@/utils/url";
import axios from "axios";
import { useRouter } from "vue-router";

const router = useRouter();
const token = ref(null);

onMounted(async () => {
    token.value = localStorage.getItem('token') ?? null;
})

function gotoCateringHome() {
    router.push('/owner-catering');
}
</script>

<template>
    <div
        class="tw-align-bottom tw-text-left tw-overflow-hidden sm:tw-align-middle sm:tw-max-w-4xl sm:tw-w-full tw-mx-auto tw-relative tw-px-6 tw-py-12">
        <div class="sm:tw-p-12 tw-p-6 tw-pt-14 tw-bg-white tw-rounded-lg tw-shadow-md">
            <h2 class="tw-sm:text-4xl tw-text-3xl tw-leading-6 tw-font-bold tw-text-center tw-text-primary tw-mb-4">
                WORK WITH US
            </h2>
            <p class="tw-sm:text-lg tw-text-base tw-text-center tw-sm:mb-12 tw-mb-6 tw-text-gray-500">
                NOW, THAT'S SOME SMART THINKING.
            </p>
            <form>
                <div>
                    <h3 class="tw-mb-3 tw-md:text-xl tw-text-sm">
                        Kategori Paket
                    </h3>
                    <select id="category"
                        class="tw-bg-gray-50 tw-text-gray-600 tw-text-sm tw-rounded-md tw-block tw-w-full tw-px-2.5 tw-py-3.5 tw-border-0 tw-ring-1 tw-ring-inset tw-ring-gray-300 tw-focus:ring-2 tw-focus:ring-inset tw-focus:ring-primary tw-transition-all tw-duration-500">
                        <option selected disabled class="tw-text-gray-300">
                            Pilih Kategori
                        </option>
                        <option value="">Ultah</option>
                        <option value="">Hajatan</option>
                        <option value="">Pernikahan</option>
                        <option value="">Arisan</option>
                    </select>
                </div>
                <div class="tw-mt-2">
                    <label for="name" class="tw-block tw-text-sm tw-font-medium tw-leading-6">
                        Nama Paket
                    </label>
                    <div class="tw-mt-2">
                        <input id="name" type="text"
                            class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-2.5 tw-px-3 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 tw-placeholder:text-gray-400 tw-focus:tw-ring-2 tw-focus:tw-ring-inset tw-focus:tw-ring-primary tw-sm:text-sm tw-sm:leading-6 tw-transition-all tw-duration-500"
                            required />
                    </div>
                </div>
                <div class="tw-mt-2">
                    <label for="price" class="tw-block tw-text-sm tw-font-medium tw-leading-6">
                        Harga Paket
                    </label>
                    <div class="tw-mt-2">
                        <input id="price" type="number"
                            class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-2.5 tw-px-3 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 tw-placeholder:text-gray-400 tw-focus:tw-ring-2 tw-focus:tw-ring-inset tw-focus:tw-ring-primary tw-sm:text-sm tw-sm:leading-6 tw-transition-all tw-duration-500"
                            required />
                    </div>
                </div>
                <div class="tw-flex tw-justify-between tw-items-center tw-gap-8 tw-mt-8">
                    <button @click="gotoCateringHome()" type="submit"
                        class="tw-flex tw-justify-center tw-rounded-md tw-shadow-md tw-px-8 tw-py-2 tw-w-fit tw-text-lg tw-text-slate-50 tw-font-semibold tw-focus-visible:tw-outline tw-focus-visible:tw-outline-2 tw-focus-visible:tw-outline-offset-2 tw-bg-green-400 hover:tw-bg-green-300 focus-visible:tw-outline-green-200 active:tw-scale-95 tw-transition-all tw-duration-500">
                        Submit
                    </button>
                    <button @click="gotoCateringHome()" type="button"
                        class="tw-flex tw-justify-center tw-rounded-md tw-shadow-md tw-px-5 tw-py-2 tw-w-fit tw-text-lg tw-text-slate-50 tw-font-semibold focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 tw-bg-red-700 hover:tw-bg-red-700 focus-visible:tw-outline-red active:tw-scale-95 tw-transition-all tw-duration-500">
                        Cancel
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>